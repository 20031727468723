import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ModalProvider} from "./components/context/Modal";
import {UserDataProvider} from "./components/context/UserData";
import {ThemeProvider} from "@mui/material/styles";
import {whiteTheme} from "./components/theme";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <UserDataProvider>
            <ModalProvider>
                <ThemeProvider theme={whiteTheme}>
                    <App/>
                </ThemeProvider>
            </ModalProvider>
        </UserDataProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
