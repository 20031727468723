import axios from "axios";
import apiRoutes from "./routes";


export const register = async ({username, password}) => {
    const response = await axios.post(
        `${apiRoutes.auth}/register`,
        {
            email: username,
            password: password,
        });
    return response.data
};


export const login = async ({username, password}) => {
    const response = await axios.post(
        `${apiRoutes.auth}/login`,
        {
            username: username,
            password: password,
        },
        {
            headers: {'content-type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
        });
    return response.data;
};


export const logout = async () => {
    const response = await axios.post(
        `${apiRoutes.auth}/logout`,
        {},
        {
            withCredentials: true,
        });
    return response.data;
}

export const fetchUserData = async () => {
    const response = await axios.get(
        `${apiRoutes.auth}/users/me`,
        {
            withCredentials: true,
        });
    return response.data;
}


export const userLoggedInRequest = async () => {
    try {
        await fetchUserData();
        return true;
    } catch (error) {
        if (error?.response?.status === 401) {
            return false;
        } else {
            throw error;
        }
    }
}


export const updateUserData = async (data) => {
    const response = await axios.patch(
        `${apiRoutes.auth}/users/me`,
        data,
        {
            withCredentials: true,
        });
    return response.data;
}
