import React, {useEffect, useRef, useState} from "react";
import {useModalContext} from "./context/Modal";
import DefaultButton from "./Button";
import {login, userLoggedInRequest} from "../api/auth";
import {Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography} from "@mui/material";
import {validateEmail, validatePassword} from "../utils/validators";
import {useUserDataContext} from "./context/UserData";
import {styled, useTheme} from "@mui/material/styles";


const StyledTextField = styled(TextField)(({theme}) => ({
        '& .MuiFormControl-root': {
            '&.Mui-error': {
                color: theme.palette.error.light,
            }
        },
        '& .MuiInputLabel-root': {
            '&.Mui-focused': {
                color: theme.palette.text.secondary,
            },
            '&.Mui-error': {
                color: theme.palette.error.light,
            }
        },
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderWidth: '1px',
                borderColor: theme.palette.secondary.dark,
            },
            '&.Mui-focused fieldset': {
                borderWidth: '1px',
                borderColor: theme.palette.secondary.light,
            },
        },
        '& .MuiInputBase-root': {
            color: 'secondary.main',
        },
    })
)


const LoginModal = () => {
    const theme = useTheme();
    const {openModals, closeModal} = useModalContext();
    const {setUserLoggedIn} = useUserDataContext();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const loginButtonRef = useRef(null);
    const [errors, setErrors] = useState(
        {email: '', password: '', general: ''}
    )

    const updateUserEmail = (e) => setUsername(e.target.value);
    const updatePassword = (e) => setPassword(e.target.value);

    const loginUser = async () => {
        await login({username, password});
        const result = await userLoggedInRequest();
        setUserLoggedIn(result);
        closeLoginModal();
    }

    const handleLogin = async () => {
        let valid = true;
        let emailError = '';
        let passwordError = '';
        let generalError = '';

        const emailInvalid = validateEmail(username);
        const passwordInvalid = validatePassword(password);

        if (emailInvalid) {
            valid = false;
            emailError = emailInvalid;
        }

        if (passwordInvalid) {
            valid = false;
            passwordError = passwordInvalid;
        }

        if (valid) {
            try {
                await loginUser();
            } catch (error) {
                if (error.response.data.detail === 'LOGIN_BAD_CREDENTIALS') {
                    generalError = 'Login or password incorrect';
                } else {
                    generalError = error.response.data.detail;
                }
            }
        }

        setErrors({
            email: emailError,
            password: passwordError,
            general: generalError,
        })
    };

    const closeLoginModal = () => {
        closeModal('login');
        setUsername('');
        setPassword('');
        setErrors({email: '', password: '', general: ''});
    };

    useEffect(() => {
        const handleKeyDown = async (event) => {
            if ((event.code === 'Enter') && openModals.login) {
                event.preventDefault();
                if (loginButtonRef.current) {
                    loginButtonRef.current.click();
                }
            }
        }
        document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [openModals]);


    return (
        <Dialog
            open={openModals.login}
            onClose={closeLoginModal}
            maxWidth={"sm"}
            fullWidth
            sx={{
                '& .MuiPaper-root': {
                    marginTop: {
                        md: '-10vh',
                        lg: '-25vh',
                    },
                    padding: '15px',
                    paddingTop: '10px',
                    borderRadius: '15px'
                },
            }}
        >
            <DialogTitle>Login</DialogTitle>
            <DialogContent>
                <Stack spacing={3} paddingTop={1}>
                    {errors.general && (
                        <Typography color="error" align="center">
                            {errors.general}
                        </Typography>
                    )}
                    <StyledTextField
                        theme={theme}
                        required
                        label="E-mail"
                        variant="outlined"
                        type="email"
                        value={username}
                        placeholder="my-email@some-domain.com"
                        fullWidth
                        onChange={updateUserEmail}
                        error={Boolean(errors.email)}
                        helperText={errors.email}
                    />
                    <StyledTextField
                        theme={theme}
                        required
                        label="Password"
                        variant="outlined"
                        type="password"
                        value={password}
                        fullWidth
                        onChange={updatePassword}
                        error={Boolean(errors.password)}
                        helperText={errors.password}
                    />
                </Stack>
            </DialogContent>
            <DialogActions sx={{justifyContent: 'space-around', width: '95%', margin: 'auto'}}>
                <DefaultButton
                    ref={loginButtonRef}
                    onClick={handleLogin}
                    sx={{width: '100%'}}
                >
                    LOGIN
                </DefaultButton>
                <DefaultButton
                    onClick={closeLoginModal}
                    sx={{width: '100%'}}
                >
                    CLOSE
                </DefaultButton>
            </DialogActions>
        </Dialog>
    )
}


export default LoginModal;
