import {
    FormControl,
    InputLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import {useEffect, useState} from "react";
import {updateUserData} from "../api/auth";
import config from "../config";
import DeleteIcon from '@mui/icons-material/Delete';
import {useUserDataContext} from "./context/UserData";


const UserProfile = () => {
    const {userData, setUserData} = useUserDataContext();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (userData) {
            setLoading(false)
        }
    }, [userData]);

    const updateSetting = async (e) => {
        const name = e.target.name;
        const value = e.target.value;
        try {
            await updateUserData({...userData, [name]: value})
            setUserData({...userData, [name]: value})
        } catch (error) {
            console.error('Error updating user data:', error);
        }
    }

    if (loading) {
        return <Typography>Loading...</Typography>
    }

    if (!userData) {
        return <Typography>You are not logged in!</Typography>
    }

    return (
        <Grid container spacing={2} width="80%" margin="auto">
            <Grid xs={6}>
                <Stack spacing={2}>
                    <TextField disabled value={userData.email}/>
                    <TextField disabled value={userData.interface_language}/>
                    <TwistersNumSelect userData={userData} updateSetting={updateSetting}/>
                    <TwisterLanguagesSelect userData={userData} updateSetting={updateSetting}/>
                </Stack>
            </Grid>
            <Grid xs={6}>
                <List>
                    <ListItem>
                        <ListItemText primary="This is a twister"/>
                        <ListItemIcon><DeleteIcon/></ListItemIcon>
                    </ListItem>
                </List>
            </Grid>
        </Grid>
    )
}


const TwistersNumSelect = ({userData, updateSetting}) => {
    return (
        <FormControl>
            <InputLabel id="num-twisters-show-label">Twisters on page</InputLabel>
            <Select
                labelId="num-twisters-show-label"
                value={userData["num_twisters_show"] || ""}
                name={"num_twisters_show"}
                label={"Number of twisters to show"}
                onChange={updateSetting}
            >
                <MenuItem value={1}>One</MenuItem>
                <MenuItem value={2}>Two</MenuItem>
                <MenuItem value={3}>Three</MenuItem>
            </Select>
        </FormControl>
    )
}


const TwisterLanguagesSelect = ({userData, updateSetting}) => {
    return (
        <FormControl>
            <InputLabel id="twister-languages-label">Languages of twisters</InputLabel>
            <Select
                multiple
                labelId="twister-languages-label"
                value={userData["twister_languages"] || []}
                name="twister_languages"
                label="Languages of twisters to show"
                onChange={updateSetting}
            >
                {Object.entries(config.twisterLanguages).map(([code, nativeValue]) => (
                    <MenuItem key={code} value={code}>{nativeValue}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}


export default UserProfile;
