import validator from "validator/es";


export const validateEmail = (text) => {
    return validator.isEmail(text) ? '' : 'Invalid email address';
};


export const validatePassword = (text) => {
    return text.length >= 6 ? '' : 'Password must be at least 6 characters long';
}


export const validateRepeatedPassword = (original, repeated) => {
    return original === repeated ? '' : 'Passwords you entered are not identical'
}
