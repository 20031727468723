import {createTheme} from '@mui/material/styles';


export const whiteTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#FBFCFC',
      light: '#FBFCFC',
      dark: '#AFB0B0',
      contrastText: '#2a2e2e',
    },
    secondary: {
      main: '#2a2e2e',
      light: '#545757',
      dark: '#1D2020',
      contrastText: '#FBFCFC',
    },
    background: {
      default: '#FBFCFC',
      paper: '#FBFCFC',
    },
    text: {
      primary: '#2a2e2e',
      secondary: '#424949',
      disabled: '#5a6464',
    },
    error: {
      main: '#b71c1c',
    },
  },
  typography: {
    fontFamily: 'Noto Sans',
  },
});
