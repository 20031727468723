import React, {useState} from "react";
import {useModalContext} from "./context/Modal";
import DefaultButton from "./Button";
import {register} from "../api/auth";
import {Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography} from "@mui/material";
import {validateEmail, validatePassword, validateRepeatedPassword} from "../utils/validators";


const RegisterModal = () => {
    const {openModals, closeModal} = useModalContext();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [repeatedPassword, setRepeatedPassword] = useState('')
    const [errors, setErrors] = useState(
        {email: '', password: '', repeatedPassword: '', general: ''}
    )

    const updateUserName = (e) => setUsername(e.target.value);
    const updatePassword = (e) => setPassword(e.target.value);
    const updateRepeatedPassword = (e) => setRepeatedPassword(e.target.value);
    const closeRegisterModal = () => closeModal('register');

    const handleRegister = async () => {
        let valid = true;
        let emailError = '';
        let passwordError = '';
        let repeatedPasswordError = '';
        let generalError = '';

        const emailInvalid = validateEmail(username);
        const passwordInvalid = validatePassword(password);
        const repeatedPasswordInvalid = validateRepeatedPassword(
            password, repeatedPassword
        );

        if (emailInvalid) {
            valid = false;
            emailError = emailInvalid;
        }

        if (passwordInvalid) {
            valid = false;
            passwordError = passwordInvalid;
        }

        if (repeatedPasswordInvalid) {
            valid = false;
            repeatedPasswordError = repeatedPasswordInvalid;
        }

        if (valid) {
            try {
                await register({username, password});
            } catch (error) {
                const detail = error.response.data.detail;
                if (detail === 'REGISTER_USER_ALREADY_EXISTS') {
                    generalError = 'User with this email is already registered!';
                } else {
                    generalError = detail;
                }
            }
        }

        setErrors({
            email: emailError,
            password: passwordError,
            repeatedPassword: repeatedPasswordError,
            general: generalError,
        })

    }

    return (
        <Dialog
            open={openModals.register}
            onClose={closeRegisterModal}
            maxWidth={"sm"}
            fullWidth
            sx={{
                justifyContent: 'center',
                '& .MuiPaper-root': {
                    marginTop: {
                        md: '-10vh',
                        lg: '-25vh',
                    },
                    padding: '15px',
                    paddingTop: '10px',
                    borderRadius: '15px'
                },
            }}
        >
            <DialogTitle>Register New User</DialogTitle>
            <DialogContent>
                <Stack spacing={3} paddingTop={1}>
                    {errors.general && (
                        <Typography color="error" align="center">
                            {errors.general}
                        </Typography>
                    )}
                    <TextField
                        required
                        label="E-mail"
                        variant="outlined"
                        type="email"
                        value={username}
                        placeholder="tongue@twisters.fun"
                        fullWidth
                        onChange={updateUserName}
                        error={Boolean(errors.email)}
                        helperText={errors.email}
                    />
                    <TextField
                        required
                        label="Password"
                        variant="outlined"
                        type="password"
                        value={password}
                        fullWidth
                        onChange={updatePassword}
                        error={Boolean(errors.password)}
                        helperText={errors.password}
                    />
                    <TextField
                        required
                        label="Repeat password"
                        variant="outlined"
                        type="password"
                        value={repeatedPassword}
                        fullWidth
                        onChange={updateRepeatedPassword}
                        error={Boolean(errors.repeatedPassword)}
                        helperText={errors.repeatedPassword}
                    />
                </Stack>
            </DialogContent>
            <DialogActions sx={{justifyContent: 'space-around', width: '95%', margin: 'auto'}}>
                <DefaultButton onClick={handleRegister} sx={{width: '100%'}}>REGISTER</DefaultButton>
                <DefaultButton onClick={closeRegisterModal} sx={{width: '100%'}}>CLOSE</DefaultButton>
            </DialogActions>
        </Dialog>
    )
}

export default RegisterModal;
