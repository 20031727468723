import axios from "axios";
import apiRoutes from "./routes";

export const addTwister = async ({text, language}) => {
    try {
        const response = await axios.post(
            `${apiRoutes.twisters}/`, {
                text: text,
                language: language,
            }, {
                withCredentials: true,
            }
        );
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};


export const fetchTwisters = async () => {
    try {
        const response = await axios.get(
            `${apiRoutes.twisters}/`, {
                withCredentials: true,
            }
        );
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}


export const deleteTwister = async (id) => {
    try {
        const response = await axios.delete(
            `${apiRoutes.twisters}/${id}/`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}
