import React, {useEffect, useState} from 'react';
import styledSC from "styled-components";
import config from "./config";
import {BrowserRouter, Outlet, Route, Routes, useNavigate} from "react-router-dom";
import NavBar from "./components/NavBar";
import {NextTwisterButton} from "./components/Button";
import AddTwisterModal from "./components/AddTwisterModal";
import LoginModal from "./components/LoginModal";
import RegisterModal from "./components/RegisterModal";
import {fetchTwisters} from "./api/twisters";
import UserProfile from "./components/UserProfile";
import {Box, CssBaseline, GlobalStyles, Typography} from "@mui/material";
import './index.css';


const globalStyles = (
    <GlobalStyles styles={{
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
    }}/>
)


const MainBlock = styledSC.div`
    height: 70vh;
    display: flex;
    justify-self: center;
`;


const LayoutContainer = styledSC.div`
    height: 90vh;
    margin: auto;
    padding: 20px;
    max-width: 1300px;
`;


const TwisterBlock = styledSC.div`
    display: grid;
    gap: 10px;
    grid-auto-rows: min-content;
    text-align: center;
    margin: auto;
    width: 70%;
    font-size: xx-large;
`;


const TwisterRow = styledSC.div`
    display: grid;
    grid-template-columns: 100px 1fr 100px;
    align-items: center;
`;


const GridRow = ({leftColumn, centerColumn, rightColumn}) => {
    return (
        <TwisterRow>
            <Box>{leftColumn}</Box>
            <Box>{centerColumn}</Box>
            <Box>{rightColumn}</Box>
        </TwisterRow>
    )
}


const Twister = ({twister}) => {
    return <GridRow
        centerColumn={
            <Typography variant="h4" m={4}>{twister.text}</Typography>
        }
    />
}

const NextButtonBlock = ({twisters, setTwisters}) => {
    const text = twisters ? "NEXT" : "START";
    return (
        <GridRow centerColumn={
            <NextTwisterButton size={"medium"} text={text} setTwisters={setTwisters}/>
        }/>
    )
}


function Layout() {
    return (
        <LayoutContainer>
            <CssBaseline/>
            {globalStyles}
            <NavBar/>
            <Outlet/>
        </LayoutContainer>
    )
}


function Home() {
    const [twisters, setTwisters] = useState(null);

    useEffect(() => {
        const getNextTwisters = async () => {
            const response = await fetchTwisters();
            setTwisters(response);
        };
        getNextTwisters();
    }, []);

    return (
        <MainBlock>
            <LoginModal/>
            <RegisterModal/>
            <AddTwisterModal setTwisters={setTwisters}/>
            <TwisterBlock>
                {twisters && (twisters.map((twister) => (
                    <Twister key={`twister-${twister.id}`} twister={twister}/>
                )))}
                <NextButtonBlock twisters={twisters} setTwisters={setTwisters}/>
            </TwisterBlock>
        </MainBlock>
    )
}


function NotFound() {
    return <div>Not found! :(</div>;
}


const IndexPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate(config.appRoutes.readTwisters);
    }, [navigate]);

    return null;
}


function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<Layout/>}>
                    <Route index element={<IndexPage/>}/>
                    <Route path={`${config.appRoutes.readTwisters}*`} element={<Home/>}/>
                    <Route path={`${config.appRoutes.profile}*`} element={<UserProfile/>}/>
                    <Route path="*" element={<NotFound/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
