import React, {createContext, useContext, useState} from "react";


const ModalContext = createContext(null);
export const ModalProvider = ({children}) => {
    const [openModals, setOpenModals] = useState({
        login: false,
        register: false,
        addTwister: false,
    });

    const openModal = (modalName) => {
        setOpenModals((prev) => ({...prev, [modalName]: true}))
    };

    const closeModal = (modalName) => {
        setOpenModals((prev) => ({...prev, [modalName]: false}))
    };

    return (
        <ModalContext.Provider value={{openModals, openModal, closeModal}}>
            {children}
        </ModalContext.Provider>
    )
}
export const useModalContext = () => useContext(ModalContext);
