const config = {
    appRoutes: {
        readTwisters: '/twisters/',
        profile: '/profile/',
    },
    twisterLanguages: {
        en: 'English',
        ru: 'Русский'
    }
};

export default config;
