import styled from "styled-components";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import React from "react";
import DefaultButton from "./Button";
import logoColor from "../img/logo-high-res-transparent.png"
import logoWhite from "../img/logo-high-res-transparent-white.png"
import logoWhitePale from "../img/logo-no-background-pale.png"
import config from "../config";
import {useModalContext} from "./context/Modal";
import {logout, userLoggedInRequest} from "../api/auth";
import {useUserDataContext} from "./context/UserData";


const NavBarOuterContainer = styled.div`
    margin: 20px auto;
`;

const NavBarInner = styled.div`
    display: flex;
`;

const NavItem = styled(NavLink)`
    text-decoration: none;
    color: #EFEBE9;
`;


const Logo = styled.img`
    height: 60px;
    padding: 10px;
`;


const NavLogo = styled(NavLink)`
    height: 100%;
`;


const NavLogoContainer = styled.div`
    flex-grow: 1;
`;

function NavBar() {
    const {openModal} = useModalContext();
    const {userLoggedIn, setUserLoggedIn} = useUserDataContext();
    const {pathname} = useLocation();
    const navigate = useNavigate();

    const openLoginModal = () => openModal("login");
    const openRegisterModal = () => openModal("register");
    const openAddTwisterModal = () => openModal("addTwister");
    const openReadTwisters = () => navigate(config.appRoutes.readTwisters);

    const logoutUser = async () => {
        await logout();
        const result = await userLoggedInRequest();
        setUserLoggedIn(result);
        openReadTwisters();
    }

    const AuthenticatedUserControls = () => {
        return (
            <>
                {pathname === config.appRoutes.readTwisters ? (
                    <DefaultButton onClick={openAddTwisterModal}>Add New Twister</DefaultButton>
                ) : (
                    <DefaultButton onClick={openReadTwisters}>Read Twisters</DefaultButton>
                )}
                <NavItem to={config.appRoutes.profile} end><DefaultButton>Profile</DefaultButton></NavItem>
                <DefaultButton onClick={logoutUser}>Logout</DefaultButton>
            </>
        )
    }

    const NonAuthenticatedUserControls = () => {
        return (
            <>
                <DefaultButton onClick={openLoginModal}>Login</DefaultButton>
                {/*<DefaultButton onClick={openRegisterModal}>Register</DefaultButton>*/}
            </>
        )
    }

    const UserControls = ({userLoggedIn}) => {
        return (
            <>
                {userLoggedIn ? <AuthenticatedUserControls/> : <NonAuthenticatedUserControls/>}
            </>
        )
    }

    return (
        <NavBarOuterContainer>
            <NavBarInner>
                <NavLogoContainer>
                    <NavLogo to="/" end>
                        {/*<Logo src={logoWhite} alt={"site logo"}/>*/}
                        <Logo src={logoWhitePale} alt={"site logo"}/>
                    </NavLogo>
                </NavLogoContainer>
                <UserControls userLoggedIn={userLoggedIn}/>
            </NavBarInner>
        </NavBarOuterContainer>
    )
}

export default NavBar;
