import {styled} from "@mui/material/styles";
import Button from "@mui/material/Button";
import React, {useEffect, useRef} from "react";
import {fetchTwisters} from "../api/twisters";
import {Tooltip, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";


const DefaultButton = styled(Button)(({theme}) => {
    return {
        margin: '0.5rem',
        // backgroundColor: 'inherit',
        fontSize: 'large',
        padding: 15,
        color: theme.palette.grey[400],

        '&:hover': {
            cursor: 'pointer',
            borderColor: theme.palette.text.primary,
            color: theme.palette.text.primary,
        },
        // '&:hover': {
        //     backgroundColor: '#FF8A65',
        //     borderColor: '#FF8A65',
        //     cursor: 'pointer',
        //     color: '#ffffff',
        // },
    }
});


export const NextTwisterButton = ({text, setTwisters}) => {
    const theme = useTheme();
    const buttonRef = useRef(null);
    const getNextTwisters = async (e) => {
        const twisters = await fetchTwisters();
        setTwisters(twisters);
    };

    useEffect(() => {
        const handleKeyDown = async (event) => {
            if (event.code === 'Space') {
                event.preventDefault();
                if (buttonRef.current) {
                    buttonRef.current.click();
                }
            }
        }
        document.addEventListener('keydown', handleKeyDown)
        return () => (
            document.removeEventListener('keydown', handleKeyDown)
        )
    }, []);


    return (
        <>
            <DefaultButton
                ref={buttonRef}
                variant="text"
                disableRipple
                onClick={getNextTwisters}
                sx={{
                    width: '250px',
                    margin: '50px auto',
                    marginBottom: '20px',
                    fontSize: "x-large",
                    borderTop: "1px solid",
                    borderBottom: "1px solid",
                    borderRadius: '0',
                }}>
                {text}
            </DefaultButton>
            <Typography
                variant="body1"
                color={theme.palette.grey[400]}
            >
                (SPACEBAR)
            </Typography>
        </>
    )
}


export default DefaultButton;
