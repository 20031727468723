import {createContext, useContext, useEffect, useState} from "react";
import {fetchUserData, userLoggedInRequest} from "../../api/auth";

const UserDataContext = createContext(null);
export const UserDataProvider = ({children}) => {
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const [userData, setUserData] = useState();

    useEffect(() => {
        const requestLoggedIn = async () => {
            const result = await userLoggedInRequest();
            setUserLoggedIn(result)
        }

        const requestUserData = async () => {
            const response = await fetchUserData()
            setUserData(response)
        };

        void requestLoggedIn();
        if (userLoggedIn) {
            void requestUserData();
        }
    }, [userLoggedIn])

    return (
        <UserDataContext.Provider value={{
            userLoggedIn, setUserLoggedIn,
            userData, setUserData,
        }}>
            {children}
        </UserDataContext.Provider>
    )

}
export const useUserDataContext = () => useContext(UserDataContext);
