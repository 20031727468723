import React, {useState} from "react";
import DefaultButton from "./Button";
import {useModalContext} from "./context/Modal";
import {addTwister} from "../api/twisters";
import {Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography} from "@mui/material";


const AddTwisterModal = ({setTwisters}) => {
    const {openModals, closeModal} = useModalContext();
    const [newTwisterText, setNewTwisterText] = useState("");
    const [errors, setErrors] = useState(
        {newTwister: '', general: ''}
    )

    const closeAddTwisterModal = () => closeModal('addTwister');

    const submitTwister = async () => {
        let newTwisterError = ''
        let generalError = ''

        try {
            await addTwister({
                text: newTwisterText,
                language: "en",
            });

            // await setTwisters((prev) => {
            //     if (prev) {
            //         const firstTwister = Object.keys(prev)[0];
            //         return {...prev, [firstTwister]: newTwisterText}
            //     }
            //     return prev;
            // });

            closeAddTwisterModal();
            setNewTwisterText("");
        } catch (error) {
            const status = error.response.status;
            const detail = error.response.data.detail;
            if ((status === 409) && (detail === 'ALREADY_EXISTS')) {
                newTwisterError = 'Similar twister already exists';
            } else {
                generalError = detail;
            }
        }

        setErrors({
            newTwister: newTwisterError,
            general: generalError,
        })
    }

    const updateNewTwisterText = (e) => setNewTwisterText(e.target.value);

    return (
        <Dialog
            open={openModals.addTwister}
            onClose={closeAddTwisterModal}
            maxWidth={"sm"}
            fullWidth
            sx={{
                '& .MuiPaper-root': {
                    marginTop: {
                        md: '-10vh',
                        lg: '-25vh',
                    },
                    padding: '15px',
                    paddingTop: '10px',
                    borderRadius: '15px'
                },
            }}
        >
            <DialogTitle>Add New Twister</DialogTitle>
            <DialogContent>
                <Stack spacing={3} paddingTop={1}>
                    {errors.general && (
                        <Typography color="error" align="center">
                            {errors.general}
                        </Typography>
                    )}
                    <TextField
                        required
                        autoFocus
                        multiline
                        minRows={3}
                        label="New twister text"
                        variant="outlined"
                        type="text"
                        value={newTwisterText}
                        placeholder="Top chopstick shops stock top chopsticks."
                        fullWidth
                        onChange={updateNewTwisterText}
                        error={Boolean(errors.newTwister)}
                        helperText={errors.newTwister}
                    />
                </Stack>
            </DialogContent>
            <DialogActions sx={{justifyContent: 'space-around', width: '95%', margin: 'auto'}}>
                <DefaultButton
                    onClick={submitTwister}
                    sx={{width: '100%'}}
                >
                    ADD
                </DefaultButton>
                <DefaultButton
                    onClick={closeAddTwisterModal}
                    sx={{width: '100%'}}
                >
                    CLOSE
                </DefaultButton>
            </DialogActions>
        </Dialog>
    )
}

export default AddTwisterModal;
